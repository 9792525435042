import React from 'react';
import styled from 'styled-components';
import { BlankBox, Lineup, Page, Section, SplitHero, Zip } from 'src/components';
import {
  BenefitsAdvisorSection,
  HowItWorksSection,
  OneAppSection,
  QuotesSection,
} from 'src/sections';
import { $t } from 'src/utils';

const SImg = styled.img`
  filter: var(--svgFilter);
  height: 72px;
  margin-bottom: 12px;
  opacity: 0.5;
  @media (prefers-color-scheme: dark) {
    filter: var(--svgFilter) saturate(0%) brightness(200%);
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`;

interface GetStartedProps {
  pageContext: {
    subtitle?: string;
    title: string;
  };
}

export default function GetStarted({ pageContext }: GetStartedProps) {
  const { subtitle, title } = pageContext;

  return (
    <Page>
      <SplitHero
        eb={$t({
          id: 'get-started.pretitle',
          defaultMessage: 'Official partner of Healthcare.gov',
        })}
        title={
          title || $t({ id: 'get-started.title', defaultMessage: 'Benefits for the self-employed' })
        }
        subtitle={
          subtitle ||
          $t({
            id: 'home.subtitle',
            defaultMessage: 'Find health, dental, and vision insurance that fits your life.',
          })
        }
        accessory={
          <BlankBox color="var(--g-cerise)" screen>
            <img src="/screens/22-home.webp" height={600} alt="Phone depicting Catch home screen" />
          </BlankBox>
        }
      >
        <Zip cta="Get started" />
      </SplitHero>
      <Section small>
        <Lineup height={48} numPerRow={8}>
          <SImg loading="lazy" src="/logos/standard/nyt.webp" />
          <SImg loading="lazy" src="/logos/standard/wsj.webp" />
          <SImg loading="lazy" src="/logos/standard/techcrunch.webp" />
          <SImg loading="lazy" src="/logos/standard/forbes.webp" />
          <SImg loading="lazy" src="/logos/standard/fortune.webp" />
          <SImg loading="lazy" src="/logos/standard/bi.png" />
          <SImg loading="lazy" src="/logos/standard/realsimple.webp" />
          <SImg loading="lazy" src="/logos/standard/entrepreneur.webp" />
        </Lineup>
      </Section>
      <HowItWorksSection />
      <BenefitsAdvisorSection />
      <QuotesSection />
      <OneAppSection title="Start shopping today">
        <Zip cta="Get started" />
      </OneAppSection>
    </Page>
  );
}
